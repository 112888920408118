var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modals.edition)?_c('Modal',{staticClass:"editions",on:{"close":function($event){return _vm.closeEdition()}}},[(!_vm.isCustom)?_c('div',[_c('h3',[_vm._v("选择剧本")]),_c('ul',{staticClass:"editions"},[_vm._l((_vm.editions),function(edition){return _c('li',{key:edition.id,staticClass:"edition",class:['edition-' + edition.id],style:({
          backgroundImage: `url(${require('../../assets/editions/' +
            edition.id +
            '.png')})`
        }),on:{"click":function($event){return _vm.setHomeEdition(edition)}}},[_vm._v(" "+_vm._s(edition.name)+" ")])}),_c('li',{staticClass:"edition edition-custom",style:({
          backgroundImage: `url(${require('../../assets/editions/custom.png')})`
        }),on:{"click":function($event){_vm.isCustom = true}}},[_vm._v(" 自定义剧本/角色 ")])],2)]):_c('div',{staticClass:"custom"},[_c('h3',[_vm._v("加载自定义剧本/角色")]),_vm._v(" 若想玩自定义剧本，请在 "),_c('a',{attrs:{"href":"https://clocktower.gstonegames.com/script_tool/","target":"_blank"}},[_vm._v("官方（中文）剧本工具")]),_vm._v(" 中选择想玩的角色然后上传生成的\"custom-list.json\"文件或提供包含JSON文件的URL链接。 "),_c('br'),_vm._v(" 若想玩自定义角色，请查阅关于如何编写自定义角色定义文件的文档。 "),_c('br'),_c('b',[_vm._v("请勿上传未知来源的自定义JSON文件！")]),_c('h3',[_vm._v("剧本：")]),_c('ul',{staticClass:"scripts"},_vm._l((_vm.scripts),function(script,index){return _c('li',{key:index,on:{"click":function($event){return _vm.handleURL(script[1])}}},[_vm._v(" "+_vm._s(script[0])+" ")])}),0),_c('input',{ref:"upload",attrs:{"type":"file","accept":"application/json"},on:{"change":_vm.handleUpload}}),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"button",on:{"click":_vm.openUpload}},[_c('font-awesome-icon',{attrs:{"icon":"file-upload"}}),_vm._v(" 上传JSON ")],1),_c('div',{staticClass:"button",on:{"click":_vm.promptURL}},[_c('font-awesome-icon',{attrs:{"icon":"link"}}),_vm._v(" 输入URL ")],1),_c('div',{staticClass:"button",on:{"click":_vm.readFromClipboard}},[_c('font-awesome-icon',{attrs:{"icon":"clipboard"}}),_vm._v(" 使用剪贴板中的JSON ")],1),_c('div',{staticClass:"button",on:{"click":function($event){_vm.isCustom = false}}},[_c('font-awesome-icon',{attrs:{"icon":"undo"}}),_vm._v(" 返回 ")],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }